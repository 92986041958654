$primary-color:         #75BE4C;
$secondary-color:       #00889D;
$tertiary-color:        #374864;
$link-color:            #4A90E2;

$support-color:         #D8D8D8;

$default-radius:        4px;

$font-large:            48px;
$font-medium:           24px;
$font-default:          20px;

$inuit-base-text-color: $tertiary-color;

$inuit-widths-columns-responsive: (
  1,
  2,
  3,
  4,
  5,
);

$inuit-widths-columns: (
  1,
  2,
  3,
  4,
  5,
);
