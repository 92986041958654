#user-login {
  input {
    display: block;
    margin-bottom: 15px;
  }

  input[type=submit] {
    @extend .button;
    @extend .button--primary;
  }
}