.node-type-contact {
  #block-system-main {
    max-width: 990px;
    width: 90%;
    margin: 0 auto;
  }
}

.node--contact {
  .form-item {
    @extend .clearfix;
  }

  @include media-query(palm) {
    .left-col {
      margin: 1em;
    }
  }

  @include media-query(lap-and-up) {
    .left-col {
      float: left;
      width: 320px;
      margin-right: 40px;
    }

    .right-col {
      overflow: hidden;
    }

    .form-textarea-wrapper, input[type=text], input[type=email], select {
      display: block;
      vertical-align: top;
      float: left;
      width: calc(100% - 11rem);
    }

    textarea {
      width: 100%;
    }

    select {
      height: 2em;
      background: #fff;
      border-radius: 0;
    }

    input[type="submit"] {
      // margin-left: 11.2rem;
      width: calc(100% - 11rem);
      float: right;
    }

    .form-item {
      label {
        width: 10rem;
        margin-right: 1rem;
        float: left;
      }
    }
  }
}
