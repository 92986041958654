.vertical-list {
  @extend .clearfix;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  text-align: left; list-style-type: none;

  a {
    @include inuit-font-size(14px);
    text-decoration: none;
    display: block; font-weight: 500;
    text-transform: uppercase;
  }

  &--narrow {
    display: none;
    max-width: 95%; margin: 0 auto;
    a { color: #fff;
      &.active:hover, &.active:focus, &:hover, &:focus { color: darken(#fff,20%); }
      &.active { color: #fff; }
    }
    @include media-query(lap-and-up) {
      display: block;
      li { width: 47.5%; margin-right: 2.5%; float: left; border-bottom: 2px solid darken(#fff,10%);
        margin-bottom: 10px;
        &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(6), &:nth-of-type(8){ margin-left: 2.5%; margin-right: 0; }
      }
    }
  }

  &--wide { display: none; padding: 0 10px;
    @at-root .footer-top & {
      display: block;
    }
    a { color: $tertiary-color; font-weight: 500;
        &:hover, &:focus { color: lighten($tertiary-color,20%); }
    }
      @include media-query(palm) {
        &:last-child &__list-element:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
    }
    &__list-element {
      border-bottom: 2px solid lighten($tertiary-color,20%);
      margin-bottom: 10px; padding-bottom: 5px;
      @include media-query(lap-and-up) {
        width: 47.5%; margin-right: 2.5%; float: left;
        &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(6),
        &:nth-of-type(8), &:nth-of-type(10){
          margin-left: 2.5%; margin-right: 0;
        }
      }
    }
  }
}

.vertical-list--wide__title {
  text-transform: uppercase;
  color: #fff;
  background-color: $tertiary-color;
  margin: 0;
  padding: 10px 10px;
  font-weight: 600;

  &:focus {
    background-color: lighten($tertiary-color, 5%);
  }

  @include media-query(portable) {
    display: block;
  }

  @include media-query(desk) {
    display: none;
  }

  img {
    float: right;
    padding-top: 5px;
  }
}

.vertical-list-wrapper {
  @extend .clearfix;

  border: 2px solid $tertiary-color;
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;

  &.is-open ul { display: block; }
  ul:first-of-type { margin-top: 10px; }
  ul:last-of-type { margin-bottom: 10px; }

  @include media-query(portable) {
    position: relative;
    margin-top: 30px;
    top: 50px;
  }
}

@include media-query(desk) {
  .vertical-list {
    &-wrapper { padding: 0; margin: 0; border: none; float:right; width: 550px;
      position: relative;
      ul:first-of-type { margin-top: 20px; }
      ul { display: block; }
    }
    &--narrow { float: right; max-width: 550px; margin-top: 10px; }

    &--wide {
      position: relative; padding-right: 0;
      float: left; width: 550px; margin-top: 10px;
      a { &:hover, &:focus { color:$secondary-color; } }
      &__list-element { width: 30%; margin-left: 0 !important; margin-right: 3% !important;
           border-color: $secondary-color;
          &:nth-of-type(1), &:nth-of-type(4), &:nth-of-type(7){
            margin-left: 3% !important;
          }
          &:nth-of-type(3), &:nth-of-type(6), &:nth-of-type(9){
            margin-right: 0 !important;
          }
      }
      &__list-element--with-bg { z-index: 5; position: relative;
        background: transparent;
        a.active { color: #fff; }
        a.active::before {
            content: " ";
            height: 40px; width: 190px;
            top: -11px; left: -20px;
            background: url("../img/menu-hover-bg.jpg");
            background-repeat: no-repeat; background-size: cover;
            position: absolute; z-index: -50;
        }
      }

      &--separated {
        position: absolute; top: -45px; left: -455px; padding-left: 0;
        a { color: #fff; text-transform: none;
          &.active:hover, &.active:focus, &:hover, &:focus { color: darken(#fff,10%); }
          &.active { color: #fff; }
        }
        li { border-bottom: none; margin-right: 11px; width: auto; }
      }
    }
  }
}
