// Set default width
.view-membership-advantage-view,
.view-dbase-action-models,
.view-references,
.view-nieuwsoverzicht,
.view-studies-en-cijfers {
  .view-filters,
  .view-content {
    max-width: 1024px;
    padding-left: 17px;
    padding-right: 17px;
    margin-left: auto;
    margin-right: auto;
  }
}

.view-membership-advantage-view {
  @at-root .teaser-box & {
    .view-content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .item-list {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0 0 20px 0;
      padding: 0 0 20px 0;
      border-bottom: 1px solid #D8D8D8;

      @at-root .teaser-box & {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.view-action-models {
  max-width: 1024px;
  padding-left: 17px;
  padding-right: 17px;
  margin-left: auto;
  margin-right: auto;

  .view-grid {
    @extend %clearfix;

    @include media-query(desk) {
      margin-left: -40px;
    }
  }

  .view-grid__item {
    float: left;
    width: 100%;

    @include media-query(desk) {
      padding-left: 40px;
    }
  }

  .view-filters {
    padding: 10px;
    border: 2px solid $support-color;
  }
}

.view-studies-en-cijfers {
  .view-filters {
    border: 2px solid #D8D8D8;
    padding: 10px;
    margin-bottom: 20px;
  }
}
