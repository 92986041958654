.side-menu {
  padding: 10px;
  border: 2px solid $support-color;

  + .side-menu {
    margin-top: 10px;
  }

  h3 { margin: 0 0 20px 0; }
  ul { list-style-type: none; padding-left: 0; margin-bottom: 0; }
  p { margin-bottom: 0; }

  li.leaf { list-style-type: none; list-style-image: none; margin-left: 0; }

  a { text-decoration: none;
    &:hover { color: darken($link-color,20%); }
  }

  select { display: block; width: 100%; border-radius: 4px;
    border: 1px solid darken($support-color, 15%);
    &:first-of-type { margin-bottom: 10px; }
  }

  div { color: $link-color;
    label {
      &:hover { cursor: pointer; }
    }
  }
}

.side-menu__checkboxes {
  margin-bottom: 20px;
}
