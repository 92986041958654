.grid-wrapper,
.grid-group {
  @extend %clearfix;
}

.grid-group {
  .grid:nth-child(1n) {
    .infotile {
      @include media-query(lap-and-up) {
        margin-right: 10px;
      }
    }
  }
}

.grid-wrapper--spaced-h-medium {
  margin-left: -10px;

  .grid {
    padding-left: 10px;
  }
}

.grid {
  float: left;
  width: 100%;
}

.grid--padded {
  padding: 5px;
}

.grid--padded-no-bottom {
  padding: 5px 5px 0 5px;
}

@include media-query(lap) {
  #grid--education { padding-left: 10px; }
}

@include media-query(lap-and-up) {
  .grid {
    &--right { float: right; }

    &--2 { width: 50%;
      &--fill { width: 50%; }
    }
    &--3 { width: 33.33%;
      &--fill { width: 66.66%; }
    }

    &--left.grid--4 {
      padding-right: 10px;
    }
    &--right.grid--4 {
      padding-left: 10px;
    }
    &--4 { width: 25%;
      &--fill { width: 75%; }
    }
  }
}
@include media-query(desk) {
  #grid--education { padding-left: 20px; }
}
