.article {
  @extend %clearfix;
  text-align: left;

  @include media-query(lap) {
    padding: 15px;
  }

  @include media-query(lap-and-up) {
    text-align: left;
  }

  @include media-query(desk) {
    padding: 0;
  }

  .grid--centered {
    width: 100%;
  }

  ul {
    text-align: left;
  }

  h3 {
    @include media-query(palm) {
      padding: 0 10px;
    }
  }
}

.article__description {
  font-weight: 300;
}

.article__share {
  border-top: 1px solid #D8D8D8; text-align: center;
  padding-top: 1em;
  margin-top: 1em;

  @include media-query(lap) {
    text-align: center;
  }

  @include media-query(desk) {
    text-align: left;
  }
}

.article__image {
  width: 200px;
  margin-bottom: 10px;
}

.article__image.img--center {
  width: auto;
}

.article__overview-link {
  display: block;
}
