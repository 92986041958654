.gmap-notification {
  background-color: rgba(#fff, 0.75);
  text-align: center;
  padding: 10px;

  h3 {
    margin-top: 0;
  }

  select {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
  }

  p {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    &:hover {
      color: darken($link-color,20%);
    }
  }

  input[type="text"] {
    display: inline-block;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
  }

  .views-exposed-widget input.form-submit {
    @extend .button;
    @extend .button--primary;
    outline: 0;
    border: 0;
    margin-top: 0;
    line-height: 37px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__map {
    display: none;
  }

  .button {
    display: inline-block;
    width: 100%;

    &:hover, &:focus {
      color: darken($primary-color,20%);
    }
  }

  #edit-postal-code-wrapper {
    label {
      display: none;
    }
  }
}

.gmap-map {
  display: none;
}

@include media-query(lap-and-up) {
  .form-item-province {
    float: left;
    margin-top: 12px;
  }
  .gmap-map {
    display: block;
    margin-bottom: -20px;
  }
}

@include media-query(lap) {
  .gmap-map {
    max-width: 100%;
  }
  .gmap-notification {
    max-width: 50%;
    margin: 0 auto;
  }
}

@include media-query(desk) {
  .gmap {
    margin-left: auto;
    margin-right: auto;
   }

  .gmap-notification {
    position: relative;
    z-index: 5;
    width: 990px;
    text-align: left;
    position: absolute;
    top: 55px;
    left: 50%;
    margin-left: -495px;
    padding: 25px 35px;

    h3 {
      margin-bottom: 10px;
    }

    select {
      display: inline-block;
      width: 200px;
      margin-right: 5px;
     }

    input[type="text"] {
      width: 200px;
    }

    p {
      position: absolute;
      top: 45px;
      margin: 0 ;
      left: 68%;
    }

    .bikestore {
      left: 45%;
      margin-top: 25px;
    }

    &-wrapper {
       position: relative;
    }

    &__map {
      display: block;
    }
  }
}
