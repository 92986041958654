.feed {
  position: relative;
  padding: 20px;
  border: 2px solid #D8D8D8;

  h3 { margin-top: 0; }

  a:hover,
  a:focus {
    color: darken($link-color,20%);
  }
}

.feed__message {
  text-decoration: none;

  span {
    color: #989898;
    display: block;
    margin-bottom: 10px;
  }
}

.feed__link {
  margin-top: 30px;
  display: inline-block;
  text-decoration: none;
  font-weight: 300;
  position: absolute;
  right: 0;
}
