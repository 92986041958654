.pagination-list { list-style-type: none; display: table;
  border-spacing: 5px; border-collapse: separate;
  margin: 40px auto; padding-left: 0;
  li {  width: 40px; height: 40px; line-height: 37px; font-weight: 500;
    border: 2px solid rgba($primary-color, 0.6); border-radius: 4px;
    display: inline-block; text-align: center; margin: 0 2px;
  }

  a { text-decoration: none; color: $primary-color; display: block; }
  .previous, .next {
    position: relative;
    a {
     display: block;
     text-indent: 999px;
     overflow: hidden;
     white-space: nowrap;
     color: $secondary-color;
     position: relative;
     z-index: 10;
   }
  &:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-weight: bold;
    line-height: 38px;
    text-align: center;
    color: $tertiary-color;
    z-index: 5;
    text-indent: none;
  }
 }
  .empty { border: none; }
  .next {
    float: right;
    &:before {
      content: '››';
    }
  }

  .previous {
    float: left;
    &:before {
      content: '‹‹';
    }
  }
  .active { background-color: $primary-color; color: #fff;
    border-color: darken($primary-color,10%); }
}
