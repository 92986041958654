.button {
  color: #fff; font-weight: bold; text-decoration: none; padding: 10px;
  text-align: center; font-size: $font-default; border: 0;
  &--primary { background-color: $primary-color; color: #fff; font-weight: 500;
    border-radius: 4px; padding-left: 20px; padding-right: 20px;
    &:hover, &:focus { color: darken($primary-color,15%); }
  }
  &--action { max-width: 300px; margin-left: auto; margin-right: auto;
    border: 2px solid #fff; border-radius: $default-radius;
    background-color: lightgrey; background-color: rgba(0,0,0,0.11);
    &:hover, &:focus { background-color: grey; background-color: rgba(0,0,0,0.4); }
  }
  &--secondary { background-color: #00889E; padding-left: 10px; padding-right: 10px;
    border: 0; outline: 0; border-radius: 0 $default-radius $default-radius 0;
    &:hover, &:focus { color: darken($secondary-color,15%); }
  }
  &--multicolor { color: $primary-color; padding: 10px; display: block;
    border: 1px solid $primary-color; border-radius: $default-radius;
    max-width: 400px; margin: 0 auto;
    span { font-size: 16px; color: $secondary-color;
      font-weight: 300; display: block;
    }
    &:hover, &:focus { background-color: #8AB33A; color: #fff; }
  }
  &--facebook { width: 90px; height: 41px; display: inline-block; margin: 15px 0;
    background: url("../img/facebook-share.png") no-repeat center;
    background-size: cover;
  }
  &--twitter { width: 90px; height: 41px; display: inline-block; margin: 15px 0;
    background: url("../img/twitter-share.png") no-repeat center;
    background-size: cover;
  }
  &--fill {
    width: 100%; max-width: initial;
  }
}

@include media-query(desk){
  .button {
    &--action { max-width: initial; }
    &--secondary { padding-left: 35px; padding-right: 35px; }
  }
}
