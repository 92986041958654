/* .logo { padding: 0 10px;
  &--secondary { height: 100px; }
} */

.logo {
  max-width: 100%;
  height: auto;
  width: 303px;
}

@include media-query(desk) {
  .logo { float: left; }
}
