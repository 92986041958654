.news-grid {
  @extend %clearfix;

  @include media-query(desk) {
    margin-left: -20px;
  }
}

.news-grid__item {
  float: left;
  width: 100%;

  @include media-query(desk) {
    padding-left: 20px;
  }
}

.news-preview {
  background-color: #F3F3F3;
  padding: 20px;
  margin-top: 10px;
  color: $tertiary-color;
  position: relative;

  .label {
    display: none;
  }

  &:first-of-type {
    margin-top: 0;
  }

  img { width: 165px; height: auto; }
  span {
    margin-top: 8px; display: block; font-weight: 300; color: #989898; font-size: 16px;
    a { position: relative; z-index: 11; }
  }

  a { text-decoration: none; color: $link-color; position: absolute; top: 0; bottom: 0; left: 0; right: 0; text-indent: -999px;
    &:hover, &:focus { color: darken($link-color,20%); }
  }
}

.news-preview-wrapper {
  .views-row {
    @extend %clearfix;
    border: 1px solid #D8D8D8;

    .field-name-field-news-img, .field-name-field-news-teaserimg {
      float: left;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
}
