.paragraph {
  @extend .clearfix;
  .field-img {
     img {
       width: 100%;
       display: block;
     }
  }
  @include media-query(lap-and-up) {
   .field-img {
      width: 155px;
      float: left;
      margin-right: 1.5em;
      margin-bottom: 1.5em;
   }

   .field-text {
    overflow: hidden;

    *:first-child {
      margin-top: 0;
    }
   }

   &:nth-child(odd) {
     .field-img {
        float: right;
        margin-right: 0;
        margin-left: 1.5em;
     }
   }
  }
}