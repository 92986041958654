.grid-action-model {
  @extend %clearfix;

  @include media-query(desk) {
    margin-left: -40px;
  }
}

.grid-action-model__item {
  width: 100%;
  float: left;

  @include media-query(desk) {
    padding-left: 40px;
  }
}

.action-model-box {
  padding: 10px;
  border: 2px solid #D8D8D8;

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}
