.banner {
    text-align: center;
    position: relative;
    h1 { color: $primary-color;
      // background: linear-gradient(to right, #8cb53b 0%, #00889e 100%);
      color: #fff;
      padding: 20px;
      line-height: 1.2;
      position: relative;
      z-index: 10;

      a {
        color: inherit;
      }
    }
    .icon { display: none; }
    &__inner { h1 { text-align: center; } }
    &__message { padding: 10px; }
    &--page {
      img { display: none; }
    }

    .video-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      overflow: hidden;

      @include media-query(desk) {
        &.fill-height {
          img,
          video {
            width: auto;
            height: 100%;
          }
        }
      }
      img,
      video {
        width: 100%;
        height: auto;
      }
    }
}

@include media-query(lap){
  .banner {
    &__message { text-align: left;
      max-width: 80%;
      margin: 0 auto;
    }
  }
}

@include media-query(desk){
  .banner {
    background: url("../img/Banner.png") no-repeat center;
    background-size: cover;
    text-align: center;
    position: relative;
    display: table;
    width: 100%;
    height: 200px;
    h1 {
      color: #fff; background: none;
      display: table-cell; height: 100%; vertical-align: middle;
      padding: 130px 0;
    }
    &--page {
      background: none;
      img { display: inline-block; margin-bottom: 10px;
        height: auto; width: 100%; }
    }
    &__inner { h1 { text-align: left; } }

    &__content { display: table-cell; height: 100%; vertical-align: middle; }
    &__message { background-color: #fff; border-radius: 4px;
      max-width: 650px; text-align: left;
      margin: 50px auto; padding: 15px;
      p:first-of-type { margin-top: 0; }
      p:last-of-type { margin-bottom: 0; }
    }
    .icon { position: absolute; bottom: -65px; left: 50%;
      display: block; margin-left: -45px; }
  }
}
