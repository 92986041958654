.table,
%table {
  width: 100%;

  td {
    padding: 6px;
    background: #efefef;
  }
}

.views-table {
  @extend %table;
}
