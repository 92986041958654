.search-results {
  margin: 0;
  margin-top: 2em;
  padding: 0;

  .search-snippet-info {
    padding: 0;
  }

  .title {
    margin-top: 0;
  }

  li {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid $support-color;

    &:last-child {
      margin-bottom: 2em;
      padding-bottom: 2em;
      border-bottom: 1px solid $support-color;
    }
  }
}

.search-form {
  @extend .clearfix;
  label {
    display: inline-block;
    float: left;
    vertical-align: middle;
    padding-top: 0.5em;
    margin-right: .6em;
  }
  input[type=text], input[type=submit] { display: block; border: 2px solid lightgrey; }
  input[type=text] { border-radius: 4px 0 0 4px; border-right: none;
    float: left; padding: 9px; width: 200px; outline: 0; }
  input[type=submit] { border-radius: 0 4px 4px 0; border-left: none;
    float: left;
    width: 50px;  border: 0; height: 46px;
    background: url("../img/search-icon--blue.png") no-repeat center;
    background-color: $tertiary-color;
    border: 0;
    font-size: 0;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}