.webform-client-form {
  @extend .clearfix;
  max-width: 400px; margin: 0 auto;

  h2, .submitted { display: none; }
  label, input, select, textarea, .form-textarea-wrapper {
    max-width: 100%;
    display: block; width: 100%; max-width: 100%; }
  fieldset { border: none; margin: 0; padding: 0;
      // label { font-weight: normal; }
  }

  .form-actions {
    width: 100%;
    float: left;
    margin: 0;
  }

  .grippie {
    display: none;
  }

  input[type="submit"] {
    @extend .button;
    @extend .button--primary;
  }

  .homepage-textfield {
    position: absolute;
    visibility: hidden;
  }

  .form-item input.error, .form-item textarea.error, .form-item select.error {
    border: 1px solid red;
  }
}

@include media-query(desk){
  .webform-client-form { margin:0; margin-left: 10px; max-width: inherit;
    label, input, select, textarea, .form-textarea-wrapper {
      display: inline; width: auto; }
    label { display: inline-block; }
    input[type="text"],input[type="email"], select, textarea { width: 400px; padding: 5px; }

    input[type="submit"] {
      width: 400px; margin-left: 170px;
    }
  }
}
