.field-name-field-reference {
  @extend .clearfix;
  display: inline-block;
}

.infotile {
  position: relative;
  max-width: 4500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  background-color: #F3F3F3;
  border: 1px solid $support-color;

  ul {
    margin: 10px 0 0 0;
    padding: 0;

    li, &.leaf, &.collapsed {
      list-style: none;
      margin-bottom: 6px;
      margin-left: 0;
    }

    a { color: $link-color;
      &:hover {
        color: darken($link-color,20%);
      }
    }
  }

  .webform-client-form {
    margin: 0;
    input[type="submit"] {
      margin-left: 0;
    }
  }

  .field-label {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
    position: relative;
  }

  @at-root .node-type-bike-infrastructure-lp .main-content & > a {
    height: inherit;
    display: block;
  }

  a.wrapper-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    text-indent: -999px;
    overflow: hidden;
    white-space: nowrap;
  }

  .label {
    display: none;
  }
}

.infotile__inner {
  padding: 20px;
}

.infotile__title {
  color: $tertiary-color;
  margin: 0;
}

.infotile__date {
  color: #aaa;
}

.infotile__description {
  color: $tertiary-color;
  margin-top: 5px;
  margin-bottom: 10px;
}

@include media-query(lap-and-up) {
  .infotile { max-width: none; }
}
