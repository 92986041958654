.horizontal-list { background-color: $tertiary-color; padding-left: 0; margin: 0;
  li { display: inline-block; padding: 4px 4px; margin-right: 20px; }
  a { color: #fff; text-decoration: none; display: block;
    &:hover { color: darken(#fff,20%); }
    &:focus { color: darken(#fff,20%); }
  }
  &--multilevel {
    background-color: transparent; @include inuit-font-size(14px);
    li { margin-right: 0; padding: 4px 0; padding-right: 20px; padding-left: 6px;
      background: url("../img/blue-arrow.png") no-repeat center right 3px;
      &:first-of-type { padding-left: 0; }
      &:last-of-type { background: none; }
    }
    a { color: $link-color;
      &:hover { color: darken($link-color,20%); }
      &:focus { color: darken($link-color,20%); }
    }
    &__last { color: #989898;
      &:hover { } &:focus { }
    }
  }
}
