.header-top {
  * { display: none; }
  &__inner { display: block; }
}

.header-top { @extend .clearfix; }

.header-bottom { z-index: 10; position: relative;
  .logo-wrapper { width: 100%; display: inline-block; }
  .logo { width: 200px; margin: 0 auto; display: block; position: relative; top: 17px;}
}
.breadcrumb { margin-top: 50px; height: 1px;
  &__inner { display: none; }
}

@include media-query(desk){
  .header {
    transition: .3s border;
    min-height: 175px;

    .logo {
      width: 291px;
    }
  }
  .header-under-icon { margin-top: 85px; }

  .header-bottom {
    .logo-wrapper { width: auto; display: inline; }
  }

  .header.scrolling {
    min-height: 84px;
    border-bottom: 1px solid rgba(#000, 0.2);
    .logo {
      width: 160px;
      position: relative;
      top: 18px;
    }

    .vertical-list--wide {
      margin-top: 5px;
      margin: 10px 0 6px;
    }

    .vertical-list--wide__list-element {
      border-bottom-width: 1px;
      margin-bottom: 4px;
    }

    .header-top {
      display: none;
    }
  }

  .header {
    &, * {
      transition: 0.5s all;
    }

    .vertical-list--wide__list-element {
      transition: none;
    }

    position: fixed; top: 0; width: 100%; margin: 0 auto; z-index: 20;
    background-color: white;
  }
  .header-top { display: block; background-color: $tertiary-color;
    * { display: block; }
    .icon { float: right; margin-right: 10px;
      &:hover { background-color: #212C3D; } }
  }
  .header-bottom {
    .logo { display: inline-block; }
  }

  .vertical-list { max-width: 550px; }
  .breadcrumb { margin-top: 175px; background-color: #F3F3F3; height: auto; padding: 0;
    &__inner { display: block; padding-top: 10px; padding-bottom: 10px; }
  }
}
