.newsletter {
  @extend .clearfix;
  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
  br {
    display: none;
  }
  label {
    color:$secondary-color;
    font-weight: 500;
    text-align: left;
    width: 100%;
  }
  input[type=text], input[type=email] {
    border: 2px solid $secondary-color;
    border-radius: 4px 0 0 4px;
    float: left;
    line-height: 20px;
    padding: 10px;
    color: black;
    width: 65%;
    display: inline-block;
  }
  input[type=submit] {
    width: 35%;
    margin-left: -5px;
    display: inline-block;
    @include inuit-font-size(14px);
    float: left;
    line-height: 24px;
    line-height: 24px;
  }
}

@include media-query(lap) {
  .newsletter {
    input[type=text], input[type=email] {
      line-height: 19px;
    }
    input[type=submit] {
      @include inuit-font-size(18px);
    }
  }
}

@include media-query(desk) {
  .newsletter {
    max-width: initial;

    br {
      display: inline;
    }

    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 30px 0;

    label { text-align: right;
      margin-left: 17px;
      margin-right: -30px;
      float: left;
      line-height: 1.3;
      display: inline-block;
      width: auto;
    }

    input[type=text], input[type=email] {
      width: 440px;
      margin-left: 40px;
      height: 44px;
      line-height: 44px;
    }

    input[type=submit] {
      width: auto;
      @include inuit-font-size(20px);
      line-height: 43px;
      height: 44px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
