/**
 * Settings
 */
@import "../../node_modules/inuit-defaults/settings.defaults";
@import "../../node_modules/inuit-responsive-settings/settings.responsive";

/**
 * Tools
 */
@import "../../node_modules/inuit-functions/tools.functions";
@import "../../node_modules/inuit-mixins/tools.mixins";
@import "../../node_modules/inuit-responsive-tools/tools.responsive";
@import 'bower_components/inuit-tools-widths/tools.widths';

/**
 * Generic
 */
@import "../../node_modules/inuit-normalize/generic.normalize";
@import "../../node_modules/inuit-box-sizing/generic.box-sizing";

/**
 * Base
 */
@import "../../node_modules/inuit-page/base.page";

/**
 * External scss files
 */
@import "variables";
@import "helpers";

/**
 * Layout
 */
html { height: 100%; }
body { min-height: 100%; }
body { font-family: 'Ubuntu', sans-serif; }

h1 { @include inuit-font-size(20px); font-weight: 400; margin: 0; margin-top: 5px; }
h2 { @include inuit-font-size(24px); text-transform: uppercase; color: $secondary-color;
  @include media-query(palm) {
    @include inuit-font-size(17px);
  }
}
h3 { @include inuit-font-size(20px); color: $tertiary-color; }
h4 { @include inuit-font-size(32px); color: $tertiary-color; font-weight: 500;
  @include media-query(palm) { @include inuit-font-size(22px); padding-bottom: 10px; }
  position: relative; display: inline-block; text-align: center;
  margin: 0; margin-bottom: 15px;
  border-bottom: 2px solid $primary-color; margin-left: 10px; margin-right: 10px;
  span { display: none; }
}

img {
  max-width: 100%;
  vertical-align: middle;
}

a { text-decoration: none; color: #4A90E2; }

.intro { color: $secondary-color; padding: 10px;
  p { margin-top: 0; }
}

.block-system > .content {
  @extend %clearfix;
}

.page-wrapper { background: #fff; }
.page-wrapper, .header-top, .header-bottom, .actions,
.footer-top, .footer-bottom, .breadcrumb { margin: 0 auto;
  &__inner {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 17px;
    padding-right: 17px;
  }
}

.main-content {
  margin-left: auto;
  margin-right: auto;
}

.page-search #block-system-main,
.main-content__inner {
  max-width: 1024px;
  margin: 0 auto;
  padding-left: 17px;
  padding-right: 17px;
}

.main-content__thin {
  max-width: 684px;
  padding-left: 17px;
  padding-right: 17px;
}

.page-in-je-buurt .main-content__inner {
  max-width: none;
}

.node-type-info-page .region-content {
  max-width: 684px;
  padding-left: 17px;
  padding-right: 17px;
  margin-left: auto;
  margin-right: auto;
}


@include media-query(lap) {
  h1 { @include inuit-font-size(30px); }
}

@include media-query(lap-and-up) {
  h4 {
    @include inuit-font-size(48px);
    margin-left: 0;
    margin-right: 0;
    border: none;
    padding: 30px 0;
    margin-bottom: 0;

    span {
      display: block;
      width: 65px;
      height: 2px;
      background-color: $primary-color;
      position: absolute;
      top: 50%;
    }

    max-width: calc(100% - 150px);

    .h4__line {
      left: -75px;
    }

    .h4__line--second {
      right: -75px;
    }
  }
}

@include media-query(desk) {
  h1 { @include inuit-font-size(48px); }
}

input {
  line-height: 25px;
  padding: 0 5px;
}

input[type=text], input[type=email], input[type=password], textarea, select {
  border: 1px solid #CCCCCC;
}


.become-a-member-grid {
  @extend %clearfix;

  @include media-query(desk) {
    margin-left: -40px;
  }
}

.become-a-member-grid__item {
  float: left;
  width: 100%;

  @include media-query(desk) {
    padding-left: 40px;
  }
}

.news-home-grid {
  @extend %clearfix;

  @include media-query(lap-and-up) {
    margin-left: -20px;
  }
}

.news-home-grid__item {
  float: left;
  width: 100%;

  @include media-query(lap-and-up) {
    padding-left: 20px;
  }
}

/**
 * Drupal specific
 */
.profile,
#user-login,
#user-pass {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 17px;
  padding-right: 17px;
}

/**
 * Partials
 */
@import "layout/header-layout";
@import "layout/footer-layout";
@import "layout/grid-layout";
@import "layout/webform-client-form";

/**
 * Components
 */
@import "components/button";
@import "components/infotile";
@import "components/horizontal-list";
@import "components/vertical-list";
@import "components/banner";
@import "components/newsletter";
@import "components/icon";
@import "components/logo";
@import "components/searchbar";
@import "components/teaser-form";
@import "components/article";
@import "components/feed";
@import "components/side-menu";
@import "components/news-preview";
@import "components/pagination-list";
@import "components/gmap-notification";
@import "components/node-type-become-a-member";
@import "components/messages";
@import "components/user";
@import "components/footer-menu";
@import "components/node-type-contact";
@import "components/view";
@import "components/grid-action-model";
@import "components/node-applausactie-form";
@import "components/paragraph";
@import "components/search";
@import "components/table";

/**
 * Trumps
 */
@import "../../node_modules/inuit-clearfix/trumps.clearfix";
@import 'bower_components/inuit-widths/trumps.widths';
@import 'bower_components/inuit-widths-responsive/trumps.widths-responsive';
