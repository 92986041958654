.node-type-become-a-member #block-system-main {
  .teaser-box {
    padding: 10px;
    border: 2px solid $support-color;
    margin-top: 20px;

    .cta-button {
      @extend .button;
      @extend .button--secondary;
      @include inuit-font-size(14px, false);
      margin-top: 20px;
      border-radius: 4px;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @include media-query(portable) {
    padding: 0 10px;

    h3 {
      padding: 0;
    }
  }

  .webform-client-form {
    @extend %clearfix;
    max-width: 800px;
    clear: both;
    margin: 0 auto;
    margin-top: 3em;
    padding-top: 2em;
    border-top: 1px solid $support-color;

    .form-actions {
      float: none;
      clear: both;
      text-align: center;
      input[type=submit] {
        @extend .button;
        @extend .button--primary;
        display: inline-block;
        margin: 0;
      }
    }

    .form-type-checkbox,
    .form-type-radio {
      display: block;
      float: none;
    }

    input[type=checkbox],
    input[type=radio] {
      display: inline-block;
      width: auto;
      margin-right: 4px;
      ~ label {
        position: relative;
        top: 1px;
        display: inline-block;
        width: auto;
        max-width: calc(100% - 25px);
        vertical-align: top;
      }
    }

  }

  .webform-component {
    label {
      display: block;
    }
    .resizable-textarea,
    textarea {
      width: 100% !important;
    }
    textarea,
    input {
      width: 100%;
    }
  }

  @include media-query(lap-and-up) {
    .webform-component--family-members,
    .webform-component--fname,
    .webform-component--name,
    .webform-component--number,
    .webform-component--bus,
    .webform-component--zipcode,
    .webform-component--country,
    .webform-component--mail,
    .webform-component--membership-forwho,
    .webform-component--remarks,
    .webform-component--partner-fname,
    .webform-component--partner-name,
    .webform-component--gift-message,
    .webform-component--street {
      float: left;
      clear: left;
      width: 49%;

      input {
        width: 100%;
      }
    }

    .webform-component--gift-name,
    .webform-component--membership-gift {
      clear: left;
    }


    .webform-component--family-members,
    .webform-component--zipcode {
      label {
        display: inline;
      }
      &, input {
        width: auto;
      }
    }

    .webform-component--mail,
    .webform-component--partner-name,
    .webform-component--name {
      clear: none;
      float: right;
    }

    .webform-component--street {
      width: 59%;
    }

    .webform-component--bus,
    .webform-component--number {
      clear: none;
      width: 18.5%;
      margin-left: 2%;
    }
  }

  .webform-component--remarks{
    width: 100%;
  }

  .homepage-textfield {
    display: none;
  }
}
