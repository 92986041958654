.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.align { text-align: left;
  &--right { text-align: right; }
  &--center { text-align: center; }
}
.float {
  &--left { float: left; }
  &--right { float: right; }
}
.font-style {
  &--italic { font-style: italic; }
}

.font-soft {
	font-weight: 300;
	color: #989898;
}

.shadow {
  padding-bottom: 10px;
  // border-bottom: 3px solid $tertiary-color;
  box-shadow: 0 0 6px 7px rgba(#000, 0.5);
}

.img {
	&--left {
	margin-right: 20px;
	float: left;
	}

	&--right {
	margin-left: 20px;
	float: right;
	}

	&--center {
		height: 150px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
}