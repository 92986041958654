.teaser-form { text-align: center; margin-bottom: 20px; padding: 10px;
  p { margin-top: 0; }
  &__elements { margin-bottom: 20px; }
  .button { background-color: $secondary-color; border-radius: 4px;  }
}

@include media-query(lap-and-up){
  .teaser-form { text-align: left;
    &__elements { background-color: #fff; border-radius: 5px;
      padding: 10px; }
    .button { margin-bottom: 20px; }
  }
}
@include media-query(desk){
  .teaser-form {
    p { color: #fff; }
    .button { background-color: lightgrey; background-color: rgba(0,0,0,0.11); }
  }
}
