.actions { margin-top: 20px;
  background: rgb(140,181,59); /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhjYjUzYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDg4OWUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left,  rgba(140,181,59,1) 0%, rgba(0,136,158,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(140,181,59,1)), color-stop(100%,rgba(0,136,158,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,  rgba(140,181,59,1) 0%,rgba(0,136,158,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,  rgba(140,181,59,1) 0%,rgba(0,136,158,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left,  rgba(140,181,59,1) 0%,rgba(0,136,158,1) 100%); /* IE10+ */
  background: linear-gradient(to right,  rgba(140,181,59,1) 0%,rgba(0,136,158,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8cb53b', endColorstr='#00889e',GradientType=1 ); /* IE6-8 */

  padding: 20px 10px; text-align: center;
  .button { display: block; margin-bottom: 10px; }
}

.footer-top {
  @extend .clearfix;
  text-align: center;
  background-color: lighten($tertiary-color,10%);

  .logo { display: inherit; margin: 0px auto; margin-top: 15px; }

  .float--right {
    float: none;
  }

  .footer-secondary {
    text-align: center;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #839db9;
    @include inuit-font-size(14px);
    a {
      color: $primary-color;
    }
  }
}

.footer-top__inner__left {
  width: 100%; line-height: 1.5;

  span { color: #fff; margin-top: 10px; display: inline-block; width: 100%;
    span { color: lighten(gray,20%); margin: 0; }
  }
}

.footer-top__inner {
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-bottom { background-color: $tertiary-color; color: lightgrey;
  text-align: center;
  span, div { width: 100%; }
  img { display: block; padding-left: 50%; margin-left: -12.5px; }
  div, img { float: none; }
  a { color: lighten(#4A90E2, 20%); &:hover { text-decoration: underline;}}
}

.footer-bottom__inner {
  padding-top: 10px;
  padding-bottom: 10px;
}


@include media-query(lap-and-up) {
  .actions { padding: 35px;
    .button { display: inline-block; width: 185px;
      margin-right: 20px; margin-bottom: 0; }
  }
}

@include media-query(desk) {
  .actions { padding: 35px;
    .button { display: inline-block; width: 252px;
      margin-right: 20px; margin-bottom: 0; }
  }

  .footer-top {
    text-align: left;
    .float--right { float: right; }
    .logo { margin: 0; margin-top: 25px;  }
    .icon { display: none; }

    &__inner__left { width: 35%;
      span { margin-top: 10px; display: inline-block; width: auto;
        span { margin: 0; }
      }
    }
  }

  .footer-top__inner {
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .footer-bottom { background-color: $tertiary-color; color: #fff;
    text-align: left;
    &__inner { position: relative;
      span { width: inherit; @include inuit-font-size(14px); }
      div {  position: absolute; left: 440px; top: 10px; width: 550px; }
      img { display: inline-block; padding: 0; margin: 0;
        position: absolute; left: 530px; top: 0;
        height: 20px; margin-top: 2px; }
    }
  }
}
