/* <div class="searchbar-wrapper">
  <div class="searchbar">
    <input class="searchbar__field" type="text"/>
    <span class="searchbar__submit"></span>
  </div>
</div> */

.searchbar { display: block; @extend .clearfix;
  input {
    line-height: 1;
  }
  &-wrapper { display: block;
    position: absolute; top: 180px; left: 2.5%; width: 95%; z-index: 5;
  }
  @include media-query(portable) {
    width: 100%;
    &-wrapper {
      z-index: 999;
      top: 90px;
    }
  }

  input[type=text], input[type=submit] { display: block; border: 4px solid lightgrey; }
  input[type=text] { border-radius: 4px 0 0 4px; border-right: none;
    float: left; padding: 9px; width: 85%; outline: 0; }
  input[type=submit] { border-radius: 0 4px 4px 0; border-left: none;
    float: left; width: 15%; height: 44px;
    background: url("../img/search-icon.png") no-repeat center;
    background-color: $tertiary-color;
    border: 0;
    height: 38px;
    font-size: 0;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

@include media-query(lap){
  .searchbar {
    input[type=text] { width: 90%; }
    input[type=submit] { width: 10%; }
  }
}

@include media-query(desk){
  .searchbar { float: right; margin: 0;
    &-wrapper { position: inherit; width: auto; z-index: 5; }
    input[type=text] {
      padding: 8px;
      margin-top: 6px;
      display: inline-block;
      border: none;
      float: left;
      width: 0;
      transition: 0.3s width ease-in-out, 0.2s opacity;
      opacity: 0;
    }
    &.is-open input[type=text] {
      width: 250px;
      opacity: 1;
    }
    input[type=submit] { width: 50px; float: right; border: 0; height: 46px;
      background: url("../img/search-icon--blue.png") no-repeat center;
    }
  }
}