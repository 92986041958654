.node-applausactie-form {
  input, textarea {
    padding: .5em .8em;
    max-width: 100%;
  }

  fieldset {
    clear: left;
    border: 0;
    padding: 0;
    margin: 1em 0 3em;

    legend {
      @extend h2;
      display: block;
      width: 100%;
      background: none;

      .fieldset-legend {
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid $support-color;
      }
    }
  }

  .form-item {
    margin: 0.2em 0;

    label {
      float: none;
      width: auto;
      margin-bottom: 0.3em;
    }
    input {
      width: 100%;
    }
  }


  @include media-query(lap-and-up) {
    .form-item-title,
    .field-name-field-team-name,
    .field-name-field-team-email,
    .field-name-field-team-cell,
    .field-name-field-website-link,
    .field-name-field-organisation,
    .field-name-field-description-location,
    .field-name-field-why-location,
    .location-gmap-find-address-button-wrapper,
    .form-item-field-action-location-und-0-street,
    .form-item-field-action-location-und-0-city,
    .form-item-field-action-location-und-0-postal-code {
      width: 50%;
      float: left;
      clear: none;
      padding-right: 1em;
      clear: left;
    }

    .field-name-field-team-email,
    .field-name-field-website-link,
    .field-name-field-why-location,
    .location-gmap-find-address-button-wrapper,
    .form-item-field-action-location-und-0-city {
      padding-right: 0;
      padding-left: 1em;
      clear: none;
    }
  }

  .field-name-field-partners {
    clear: left;
  }

  .location-gmap-find-address-button-wrapper {
    margin-top: 2em;
    button {
      @extend .button--primary;
      width: 100%;
      padding: 0.6em .8em;
      border: 0;
    }
  }

  .form-item-field-action-location-und-0-locpick-user-latitude,
  .form-item-field-action-location-und-0-locpick-user-longitude {
    display: none;

    & + .description {
      display: none;
    }
  }

  .description {
    clear: left;
    font-size: 0.8em;
    margin-top: 0.5em;
  }

  .fieldset-description {
    margin-bottom: 1em;
  }

  .gmap {
    clear: both;
    float: left;
    width: 100% !important;
    margin-top: 2em;
    margin-bottom: 1em;
  }


  .field-name-field-description-location {
    &, & ~ .form-wrapper {
      margin-bottom: 2em;
    }
  }
  .form-wrapper {
    // margin-bottom: 2em;
  }

  .form-actions {
    text-align: center;
  }

  input[type=submit] {
    border: 0;
    @extend .button--primary;
    padding-left: 2em;
    padding-right: 2em;
    display: inline-block;
  }
}
