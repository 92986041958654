div.messages {
  max-width: 100%;

  margin-left: 10px;
  margin-right: 10px;

  @include media-query(desk) {
    margin-left: 0;
    margin-right: 0;
  }
}
