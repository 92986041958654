.footer-menu {
  margin-left: auto; margin-right: auto;
}
.footer-menu .menu-block-wrapper { text-align: left;
  @include inuit-font-size(14px, false); line-height: 16px;

  > ul { @extend .clearfix; margin-left: -20px; }
  > ul > li {
    float: left;
    width: 100%;
    padding-left: 20px;
    margin-bottom: 20px;

    > a {
      @include inuit-font-size(16px, normal);
      font-weight: bold;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 4px; margin-bottom: 10px;
      display: block;
    }

    > ul > li { margin-bottom: 10px;
      > a {
        display: inline-block;
        margin-bottom: 4px;
        font-weight: bold;
        color: $primary-color;

        &:hover {
          color: darken($primary-color, 10%);
        }
      }

      > ul > li {
        @include inuit-font-size(12px, normal);
        margin-bottom: 6px;

        > a {
          border-bottom: 1px solid #839db9;
        }
      }
    }
  }
}

.footer-menu {
  ul { margin: 0; padding: 0; list-style: none;
    li {
      margin: 0 0 6px 0; padding: 0;

      &.leaf,
      &.expanded { list-style: none; }
    }

    a {
      color: #fff;

      &:hover {
        color: #ccc;
      }
    }

    ul ul {
      margin-left: 10px;
    }
  }
}

@include media-query(lap) {
  .footer-menu .menu-block-wrapper {
    > ul > li {
      width: 50%;

      &:nth-child(2n+1) { clear: left; }
    }
  }
}

@include media-query(desk) {
  .footer-menu .menu-block-wrapper {
    > ul > li {
      width: 33.333333333%;
    }
  }
}
